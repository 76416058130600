<template>
    <div class="space"> </div>
    <p>
        <ShowcaseTitle v-if="infoTitle" :item="infoTitle" />
        <!-- Vitrine Grid -->
        <ShowcaseProductList :products="products" />
    </p>
</template>
<script>
import ShowcaseProductList from './ShowcaseProductList.vue';
import ShowcaseTitle from './ShowcaseTitle.vue';
export default {
    name: 'ShowcaseComponent',
    components: {
        ShowcaseProductList,
        ShowcaseTitle
    },
    props: {
        infoTitle: {
            type: Object,
            require: false
        },
        products: {
            type: Array,
            required: true
        }
    }
}
</script>

<style scoped>
.space {
    width: 100%;
    min-height: 50px !important;
}
</style>
