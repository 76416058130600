import { database, dbRef, dbSet, dbGet, dbRemove, dbPush } from '@/firebase/index';



export const getShops = async (idShop) => {
    try {
        const productsSnapshot = await dbGet(dbRef(database, `shops/${idShop}/products`));
        const productsObject = productsSnapshot.val();
        console.log(productsObject, idShop)
        // Verifica se os dados não são nulos antes de tentar acessá-los
        if (productsObject !== null) {
            // Transforma o objeto em um array
            const productsArray = Object.values(productsObject);
            return productsArray;
        } else {
            // Se os dados forem nulos, retorna um array vazio
            return [];
        }
    } catch (error) {
        console.error("Erro ao buscar os pedidos:", error);
        throw error;
    }
}



export const getShop = (idShop, productId) => {
    const productRef = dbRef(database, `shops/${idShop}/products/${productId}`);
    return dbGet(productRef);
}

export const updateShop = (idShop, productId, updatedShopData) => {
    const productRef = dbRef(database, `shops/${idShop}/products/${productId}`);
    return dbSet(productRef, updatedShopData);
};

export const deleteShop = (idShop, productId) => {
    const productRef = dbRef(database, `shops/${idShop}/products/${productId}`);
    return dbRemove(productRef);
}
export const createShopDetail = (idShop, productId, productDetailData) => {
    const productDetailRef = dbRef(database, `shops/${idShop}/productDetails/${productId}/`);
    return dbSet(productDetailRef, productDetailData);
}


export const getShopDetails = (idShop) => {
    const productDetailsRef = dbRef(database, `shops/${idShop}/productDetails`);
    return dbGet(productDetailsRef);
}

export const getShopDetailByShopId = async (idShop, productId) => {
    try {
        const productDetailRef = dbRef(database, `shops/${idShop}/productDetails/${productId}`);
        const snapshot = await dbGet(productDetailRef);

        if (snapshot.exists()) {
            return snapshot.val();
        }

        throw new Error('Detalhes do pedido não encontrados.');
    } catch (error) {
        console.error('Erro ao buscar detalhes do pedido:', error);
        throw error;
    }
}

export const addOrderShop = (shopId, orderData) => {
    const orderRef = dbRef(database, `shops/${shopId}/orders`);
    return dbSet(orderRef, orderData);
}

export const createOrderDetailShop = (shopId, orderDetailData) => {
    const orderDetailRef = dbRef(database, `shops/${shopId}/orderDetails/`);
    return dbSet(orderDetailRef, orderDetailData);
}

export const updateShopDetail = (idShop, productDetailId, updatedShopDetailData) => {
    const productDetailRef = dbRef(database, `shops/${idShop}/productDetails/${productDetailId}`);
    return dbSet(productDetailRef, updatedShopDetailData);
};

export const deleteShopDetail = (idShop, productDetailId) => {
    const productDetailRef = dbRef(database, `shops/${idShop}/productDetails/${productDetailId}`);
    return dbRemove(productDetailRef);
}

export const createShopPaymentPix = (idShop, txid, paymentDetailsPix) => {
    const paymentDetailsPixRef = dbRef(database, `shops/${idShop}/paymentDetailsPix/${txid}/`);
    return dbSet(paymentDetailsPixRef, paymentDetailsPix);
}

export const incrementLikeProductIntoShop = (shopId, like) => {
    const orderRef = dbRef(database, `shops/${shopId}/likes`);
    const newOrderRef = dbPush(orderRef);
    return dbSet(newOrderRef, like);
}
