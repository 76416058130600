<template>
    <MainLayout>
        <section class="banner-interna"
            style="background-image: url(https://firebasestorage.googleapis.com/v0/b/donasolajuda.appspot.com/o/store%2Fpages%2Ffavorites%2Flike.jpg?alt=media&token=5f5dc299-73fa-41b2-8624-e4fef6a0670e);">
            <div class="pg-titulo">
                <div class="container">
                    <div class="row">
                        <div class="col-xs-12">
                            <h1 class="animated fadeIn">Favoritos</h1>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="container" v-if="getFilteredProducts">
            <div class="row">
                <div class="col-xs-12">
                    <div class="box-filtro-produtos">
                        <div class="box-filtro-itens">
                            <div class="group-right">
                                <h5>{{ getQuantityProducts }} itens</h5>
                                <div class="box-btn-group" :style="{ display: true ? 'block' : 'none' }">
                                    <!-- Selos -->
                                    <div class="btn-group js-filtro-dropdown js-filtro-selos"
                                        :class="{ 'open': dropdown[0] }">
                                        <span class="btn btn-line dropdown-toggle" data-toggle="dropdown"
                                            @click="openDropdown(0)">Selos</span>
                                        <div class="dropdown-menu lg">
                                            <div class="dropdown-content">
                                                <div class="group-selos categorias">
                                                    <span v-for="seal in seals" :key="seal.id" class="item-selo"
                                                        data-toggle="tooltip" :title="seal.name">
                                                        <div class="check-selo">
                                                            <input type="checkbox" :id="'selos_' + seal.id"
                                                                :value="seal" v-model="selectedSeals">
                                                            <label :for="'selos_' + seal.id">
                                                                <img :src="seal.image" :alt="seal.name" width="50"
                                                                    height="50">
                                                            </label>
                                                        </div>
                                                        <span class="badge">{{ seal.count }}</span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Ordenação -->
                                    <div class="btn-group js-filtro-dropdown" :class="{ 'open': dropdown[1] }">
                                        <span class="btn btn-line dropdown-toggle" data-toggle="dropdown"
                                            @click="openDropdown(1)">Ordenação</span>
                                        <div class="dropdown-menu">
                                            <div class="dropdown-content">
                                                <div class="check-custom">
                                                    <label v-for="(option, index) in sortingOptions" :key="index"
                                                        :for="'ordenacao_' + option.value">
                                                        <input type="radio" name="ordenacao"
                                                            :id="'ordenacao_' + option.value" :value="option"
                                                            v-model="selectedSorting">
                                                        <i class="fa fa-circle-o"></i><i
                                                            class="fa fa-check-circle-o"></i>
                                                        {{ option.label }}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <button @click="applyFilters"
                                        class="btn btn-filtrar js-filtro-aplicar">Aplicar</button>
                                </div>
                            </div>
                        </div>
                        <div class="box-filtro-resultado js-filtro-resultado"
                            v-if="selectedSeals.length > 0 || selectedSorting">
                            <h6>Filtros ativos</h6>
                            <div class="group scroll-mob">
                                <a v-for="(seal, index) in selectedSeals" :key="'seal-' + index" href="javascript:;"
                                    class="item-resultado js-filtro-range" @click="clearSealFilter(index)">
                                    {{ seal.name }}
                                    <span class="remove-filter">&times;</span>
                                </a>
                                <a v-if="selectedSorting" href="javascript:;" class="item-resultado js-filtro-range"
                                    @click="clearSortFilter()">
                                    {{ selectedSorting.label }}
                                    <span class="remove-filter">&times;</span>
                                </a>
                            </div>
                            <span class="btn btn-2 js-filtro-limpar" @click="filtersClean">Limpar filtros</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="box-order-mobile">
                <section class="vitrine-home order">
                    <div class="container">

                        <div class="row">
                            <h1 class="seo sr-only">Deseja Comprar Comida Congelada? Conheça a Dona Sol Ajuda Congelados
                                Especiais! A
                                Melhor Solução em Comidas Prontas Congeladas em Rio de Janeiro.</h1>
                            <div class="col-xs-12" v-if="getFilteredProducts">
                                <article class="" v-for="category in getFilteredProducts" :key="category.categoryId">
                                    <ShowcaseComponent :infoTitle="{ ...category, products: undefined }"
                                        :products="category.products || []" />
                                </article>
                            </div>
                        </div>


                    </div>
                </section>
            </div>
        </section>

        <div class="col-xs-12" v-else>
            <div class="row">
                <div class="alert alert-info">Não há produtos na sua lista de Desejos</div>
            </div>
        </div>
    </MainLayout>
</template>

<script>
import MainLayout from '@/layouts/MainLayout.vue';
import ShowcaseComponent from '@/components/ShowcaseComponent.vue'

import { mapActions, mapGetters } from 'vuex';

export default {
    components: {
        MainLayout,
        ShowcaseComponent
    },
    data() {
        return {
            sortingOptions: [
                { label: 'Menor preço', value: 'low_to_high' },
                { label: 'Maior preço', value: 'high_to_low' },
                { label: 'A - Z', value: 'a_to_z' },
                { label: 'Z - A', value: 'z_to_a' }
            ],
            seals: [],
            selectedSeals: [],
            selectedSorting: null,
            filteredCategoriesProducts: [],
            categoriesWishList: null,
            dropdown: [
                false,
                false,
            ],
            quantityProducts: 0
        };
    },
    computed: {
        ...mapGetters('shop', ['getProductsFromWishListShop']),
        ...mapGetters('user', ['getLikes']),
        getFilteredProducts() {
            if (this.filteredCategoriesProducts.length) {
                return this.filteredCategoriesProducts
            } else {
                return false
            }
        },
        getQuantityProducts() {
            return this.quantityProducts
        },
        getCategoriesWishList() {
            return this.categoriesWishList
        }

    },
    async beforeMount() {
        try {

            await this.initializeFilters()
            console.log({
                getProductsFromWishListShop: this.getProductsFromWishListShop,
            })
        } catch (error) {
            console.error(error)
        }
    },
    methods: {
        ...mapActions('shop', ['loadMisingProduct']),
        ...mapActions('user', ['setProductsFromWishList']),
        async initializeFilters() {
            // await this.loadMisingProduct()
            await this.setProductsFromWishList()
            console.log("axé")

            // Adiando a chamada para filterProducts após a configuração inicial
            this.$nextTick(() => {
                this.getAllSeals()
                this.filterProducts();
            });
        },
        getAllSeals() {
            const allSeals = {};
            for (const category of this.getProductsFromWishListShop) {

                const products = category.products;
                products.forEach(product => {
                    product.measurementsOptions.forEach(option => {
                        if (option.seals && Array.isArray(option.seals)) {
                            option.seals.forEach(seal => {
                                if (!allSeals[seal.sealId]) {
                                    allSeals[seal.sealId] = {
                                        id: seal.sealId,
                                        image: seal.image,
                                        name: seal.title,
                                        count: 1
                                    };
                                } else {
                                    allSeals[seal.sealId].count++;
                                }
                            });
                        }
                    });
                });
            }
            this.seals = Object.values(allSeals);
        },
        filterProducts() {
            let quantityProducts = 0
            this.filteredCategoriesProducts = []; // Reset filtered products
            const categoriesWishList = JSON.parse(JSON.stringify(this.getProductsFromWishListShop)); // Copy products
            for (const category of categoriesWishList) {
                let filteredCategoryProducts = category.products // Copy products

                if (this.selectedSeals.length > 0) {
                    filteredCategoryProducts = this.filterProductsBySeal(filteredCategoryProducts);
                }

                if (this.selectedSorting) {
                    filteredCategoryProducts = this.sortProducts(filteredCategoryProducts, this.selectedSorting.value);
                }

                if (filteredCategoryProducts.length) {
                    // Verifique se selectedProduct é uma opção válida dentro de measurementsOptions
                    filteredCategoryProducts.forEach(product => {
                        const matchingOption = product.measurementsOptions.find(option => option.productId === product.selectedProduct);
                        if (!matchingOption) {
                            // Se não houver correspondência, atualize selectedProduct para a primeira opção
                            //product.selectedProduct = product.measurementsOptions[0].productId;
                        }
                    });
                    quantityProducts += filteredCategoryProducts.map(product => product.measurementsOptions.length).reduce((a, b) => a + b, 0)

                    this.filteredCategoriesProducts.push({ ...category, products: filteredCategoryProducts }); // Update filtered products
                    console.log({
                        "this.filteredCategoriesProducts": this.filteredCategoriesProducts
                    })
                }
                this.quantityProducts = quantityProducts
            }
        },
        filterProductsBySeal(products) {
            const filteredCategoriesProducts = [];
            products.forEach(product => {
                const filteredOptions = product.measurementsOptions.filter(option => {
                    return option.seals.some(seal => {
                        return this.selectedSeals.some(selectedSeal => selectedSeal.id === seal.sealId);
                    });
                });
                if (filteredOptions.length > 0) {
                    const clonedProduct = { ...product, measurementsOptions: filteredOptions };
                    filteredCategoriesProducts.push(clonedProduct);
                }
            });
            return filteredCategoriesProducts;
        },
        sortProducts(products, sorting) {
            const sortMap = {
                'low_to_high': (a, b) => this.getMinPriceOfAllOptions(a) - this.getMinPriceOfAllOptions(b),
                'high_to_low': (a, b) => this.getMaxPriceOfAllOptions(b) - this.getMaxPriceOfAllOptions(a),
                'a_to_z': (a, b) => (this.getProductNameOfAllOptions(a) || '').localeCompare(this.getProductNameOfAllOptions(b) || ''),
                'z_to_a': (a, b) => (this.getProductNameOfAllOptions(b) || '').localeCompare(this.getProductNameOfAllOptions(a) || '')
            };

            const sortingFunction = sortMap[sorting];
            if (sortingFunction) {
                products.sort(sortingFunction);
            }
            return products;
        },
        getMaxPriceOfAllOptions(product) {
            let maxPrice = -Infinity;
            product.measurementsOptions.forEach(option => {
                const price = option.price.discount.active ? option.price.value * (1 - option.price.discount.value) : option.price.value;
                if (price > maxPrice) {
                    maxPrice = price;
                }
            });
            return maxPrice;
        },
        getMinPriceOfAllOptions(product) {
            let minPrice = Infinity;
            product.measurementsOptions.forEach(option => {
                const price = option.price.discount.active ? option.price.value * (1 - option.price.discount.value) : option.price.value;
                if (price < minPrice) {
                    minPrice = price;
                }
            });
            return minPrice;
        },
        getProductNameOfAllOptions(product) {
            // Concatenar todos os nomes das opções de medição
            return product.measurementsOptions.map(option => option.name).join(', ');
        },
        applyFilters() {
            this.filterProducts();
        },
        clearSealFilter(index) {
            this.selectedSeals.splice(index, 1);
            this.filterProducts();
        },
        clearSortFilter() {
            this.selectedSorting = null;
            this.filterProducts();
        },
        filtersClean() {
            this.selectedSeals = [];
            this.selectedSorting = null;
            this.filterProducts()
        },
        openDropdown(index) {
            this.dropdown = this.dropdown.map((d, i) => (d != true && i === index ? true : false))
        }
    },
    watch: {
        '$store.state.shop.categoryWishTest': {
            handler: function (newLikes) {
                console.log('Likes mudaram:', newLikes);
                setTimeout(() => {
                    this.categoriesWishList = this.getProductsFromWishListShop
                    this.filterProducts()
                }, 0)
            },
            deep: true // Se o getter retorna um objeto, deep:true permite observar mudanças profundas no objeto
        }
    }
};
</script>
