import { createLike, deleteLike, getLikes } from '@/services/like';
import { incrementLikeProductIntoShop } from '@/services/shop';
import {
    createAddress,
    createOrder,
    createOrderDetail,
    getAddresses,
    getOrderDetailByOrderId,
    getOrders,
    getAddressByAddressId
} from '@/services/user';


const state = {
    orders: [],
    ordersDetails: [],
    modalPixVisible: false,
    orderDetailsSelected: null,
    likes: {},
    addresses: {},
    address: {}
    // addressesObject: {}
};

const mutations = {
    ADD_ORDER_USER(state, order) {
        state.orders.push(order)
    },
    ADD_ORDER_DETAILS_USER(state, orderDetails) {
        state.ordersDetails.push(orderDetails)
    },
    ABRIR_MODAL_PIX(state) {
        state.modalPixVisible = true;
    },
    FECHAR_MODAL_PIX(state) {
        state.modalPixVisible = false;
    },
    SET_ORDER_DETAILS_SELECTED(state, orderDetails) {
        state.orderDetailsSelected = orderDetails
    },
    ADD_LIKE(state, like) {
        state.likes = { ...state.likes, ...like }
    },
    REMOVE_LIKE(state, productId) {
        delete state.likes[productId]
    },
    SET_ORDERS(state, orders) {
        state.orders = orders
    },
    SET_ORDERS_DETAILS(state, ordersDetails) {
        state.ordersDetails = ordersDetails
    },
    SET_LIKES(state, likes) {
        console.log({ likes })
        state.likes = { ...state.likes, ...likes }
    },
    SET_ADDRESSES(state, addresses) {
        state.addresses = { ...state.addresses, ...addresses }
    },
    SET_ADDRESS(state, address) {
        state.address = address
    },

};

const actions = {
    async addOrderUser({ commit, rootGetters }, order) {
        const user = rootGetters['auth_google/currentUser']
        await createOrder(user.uid, '1', order)
        commit('ADD_ORDER_USER', order)
    },
    async addOrderDetailsUser({ commit, rootGetters }, orderDetails) {
        const user = rootGetters['auth_google/currentUser']
        await createOrderDetail(user.uid, '1', orderDetails.orderId, orderDetails)
        commit('ADD_ORDER_DETAILS_USER', orderDetails)
    },
    abrirModalPix({ commit }) {
        commit('ABRIR_MODAL_PIX');
    },
    fecharModalPix({ commit }) {
        commit('FECHAR_MODAL_PIX');
    },
    async addLike({ commit, rootGetters }, { router, like }) {
        try {
            const user = rootGetters['auth_google/currentUser']
            if (user) {
                await createLike(user.uid, '1', like, like.productId)
                const result = await incrementLikeProductIntoShop('1', { ...like, userId: user.uid })
                console.log({ result })
                commit('ADD_LIKE', { [like.productId]: like })
            } else {
                router.push({ path: '/login' });
            }
        } catch (error) {
            console.error(error)
        }
    },
    async removeLike({ commit, rootGetters, dispatch }, { router, like }) {
        try {
            const user = rootGetters['auth_google/currentUser']
            if (user) {
                await deleteLike(user.uid, '1', like.productId)
                commit('REMOVE_LIKE', like.productId)
                //const likes = rootGetters['user/getLikes']
                dispatch('shop/deleteProductFromWishList', like, { root: true })
                // dispatch('shop/setGroupSeals', null, { root: true })
            } else {
                router.push({ path: '/login' });
            }
        } catch (error) {
            console.error(error)
        }


    },
    async getOrdersUser({ commit, rootGetters }) {
        try {
            const user = rootGetters['auth_google/currentUser']
            if (user) {
                const orders = await getOrders(user.uid, '1')
                console.log(orders)
                commit('SET_ORDERS', orders)
            }
        } catch (error) {
            console.log(error, 'test')
        }


    },
    async setOrderDetails({ commit, state, rootGetters }, orderId) {
        try {
            const existingItem = state.ordersDetails.find(order => order?.orderId === orderId)
            if (existingItem) {
                commit('SET_ORDER_DETAILS_SELECTED', existingItem)
                console.log('nao existe', existingItem)
            } else {

                const user = rootGetters['auth_google/currentUser']
                if (user) {
                    const orderDetails = await getOrderDetailByOrderId(user.uid, '1', orderId)
                    console.log('test', orderDetails)
                    if (orderDetails) {
                        commit('SET_ORDER_DETAILS_SELECTED', orderDetails)
                        commit('ADD_ORDER_DETAILS_USER', orderDetails)
                    }
                }
            }
        } catch (error) {
            console.error(error)
        }
    },
    async getLikesUser({ commit, rootGetters }) {
        try {
            const user = rootGetters['auth_google/currentUser']
            if (user) {
                const likes = await getLikes(user.uid, '1')
                console.log({
                    likes
                })
                commit('SET_LIKES', likes)
            }
        } catch (error) {
            console.log(error, 'test')
        }


    },
    async getAddressesUser({ commit, rootGetters }) {
        try {
            const user = rootGetters['auth_google/currentUser']
            if (user) {
                const addresses = await getAddresses(user.uid, '1')
                commit('SET_ADDRESSES', addresses)
            }
        } catch (error) {
            console.log(error)
        }


    },
    async addAddress({ commit, rootGetters }, address) {
        const user = rootGetters['auth_google/currentUser']
        if (user) {
            console.log({
                address
            })
            const result = await createAddress(user.uid, '1', address)
            commit('SET_ADDRESSES', result)
        }
    },
    async setAddressByAddressId({ commit, state, rootGetters }, addressId) {
        const user = rootGetters['auth_google/currentUser']

        const address = state.addresses[addressId] || await getAddressByAddressId(user.uid, '1', addressId)
        commit('SET_ADDRESS', address)
    },
    setProductsFromWishList({ dispatch, rootGetters }) {
        const likes = rootGetters['user/getLikes']
        console.log({
            likes
        })
        dispatch('shop/loadProductsFromWishList', likes, { root: true })
        //dispatch('shop/setGroupSeals', null, { root: true })
    },
    repetirPedido({ dispatch }, list) {
        console.log({
            list
        })
        dispatch('shop/getProductsByProductIds', list, { root: true })
        dispatch('cart/addProductsList', list, { root: true })
    }
};

const getters = {
    getOrders({ orders }) {
        return orders
    },
    getLikes(state) {
        return Object.values(state.likes)
    },
    getAddresses({ addresses }) {
        return Object.values(addresses)
    },
    getOrderDetailsSelected(state) {
        return state.orderDetailsSelected
    },
    getAddressSelected({ address }) {
        return address
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
