<template>
  <div class="item col-xs-6 col-sm-4">
      <div class="box-produto js-box-produto max-height" :id="'prod-' + productIn.productId">
          <div class="video-container">
              <video id="video" width="100%" height="auto" autoplay></video>
          </div>
          <button @click="takePhoto" v-if="cameraAvailable" class="btn-tirar-foto clickable">TIRAR FOTO</button>
          <div v-if="photoTaken" class="foto-container">
              <img :src="photoDataURL" alt="Foto tirada">
              <button @click="downloadPhoto" class="btn-baixar-foto clickable">BAIXAR FOTO</button>
          </div>
          <div class="box-descricao">
              <div class="group-int">
                  <div class="escolher">
                      <div class="tipo">
                          <select name="gramas" class="form-control" v-model="measurementsOptionsSelected">
                              <option v-for="(option, index) in product.measurementsOptions" :value="option"
                                  :key="index" :selected="option.selected">
                                  {{ option.text }}
                              </option>
                          </select>
                          <button @click="addNewOption">Adicionar Opção</button>
                      </div>
                      <div class="quantidade">
                          <div class="box-number">
                              <div class="add-qtd" @click="minus"><i class="fa fa-minus"><span>-</span></i></div>
                              <input type="text" name="quantidade" class="qtd" v-model="quantity" />
                              <div class="add-qtd" @click="plus"><i class="fa fa-plus"><span>+</span></i></div>
                          </div>
                      </div>
                  </div>
                  <div class="group-valor" v-if="!productIn.price.discount.active">
                      <div class="js-valor js-desconto valor valor-desconto new-price 2">{{ getProductPrice }}</div>
                      <button @click="editPriceAndDiscount">Editar Valor e Desconto</button>
                  </div>
                  <div class="group-valor" v-else>
                      <div class="js-valor-desc valor old-price">{{ getDiscountInPercentege }} OFF</div>
                      <div class="js-valor valor old-price">de <span>{{ getProductPrice }}</span></div>
                      <div class="js-desconto valor-desconto new-price 1">por {{ getValueProductWithDiscount }}</div>
                      <button @click="editPriceAndDiscount">Editar Valor e Desconto</button>
                  </div>
              </div> <!-- /group-int -->
          </div> <!-- /box-descricao -->
      </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
      return {
          cameraAvailable: false,
          photoTaken: false,
          photoDataURL: null,
          quantity: 1,
          measurementsOptionsSelected: null,
          isFavorite: false,
          productIn: null
      };
  },
  mounted() {
      this.setupCamera();
      console.log('load-product');
      this.initProduct();
  },
  methods: {
      async setupCamera() {
          try {
              const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } });
              const video = document.getElementById('video');
              if (video) {
                  video.srcObject = stream;
                  this.cameraAvailable = true;
              } else {
                  console.error('Elemento de vídeo não encontrado.');
              }
          } catch (err) {
              console.error('Erro ao acessar a câmera: ', err);
          }
      },
      takePhoto() {
          const video = document.getElementById('video');
          if (video) {
              const canvas = document.createElement('canvas');
              canvas.width = video.videoWidth;
              canvas.height = video.videoHeight;
              const context = canvas.getContext('2d');
              context.drawImage(video, 0, 0, canvas.width, canvas.height);
              this.photoDataURL = canvas.toDataURL('image/png');
              this.photoTaken = true;
          } else {
              console.error('Elemento de vídeo não encontrado.');
          }
      },
      downloadPhoto() {
          if (this.photoDataURL) {
              const downloadLink = document.createElement('a');
              downloadLink.href = this.photoDataURL;
              downloadLink.setAttribute('download', 'photo.png');
              downloadLink.click();
          } else {
              console.error('Nenhuma foto para baixar.');
          }
      },
      minus() {
          if (this.quantity > 1) {
              this.quantity--;
          }
      },
      plus() {
          this.quantity++;
      },
      editPriceAndDiscount() {
          // Implementar a lógica para editar o valor e o desconto
      },
      addNewOption() {
          // Implementar a lógica para adicionar uma nova opção
      },
      initProduct() {
          // Simulação de inicialização do produto
          this.productIn = {
              productId: 1,
              price: {
                  value: 100,
                  discount: {
                      active: true,
                      value: 0.1 // 10% de desconto
                  }
              },
              measurementsOptions: [
                  { productId: 1, text: 'Opção 1', selected: true },
                  { productId: 2, text: 'Opção 2', selected: false }
              ]
          };
          this.measurementsOptionsSelected = this.productIn.measurementsOptions[0];
      }
  },
  computed: {
      getProductPrice() {
          return this.productIn ? this.productIn.price.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : '';
      },
      getDiscountInPercentege() {
          return this.productIn ? this.floatToPercentage(this.productIn.price.discount.value) : '';
      },
      getValueProductWithDiscount() {
          return this.productIn ? this.applyDiscountProductPrice() : '';
      },
      ...mapGetters('user', ['getLikes'])
  }
};
</script>

<style scoped>
.max-height {
  min-height: 100%!important;
  min-width: 280px !important;
}
.video-container {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
}
video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.foto-container {
  text-align: center;
}
.btn-tirar-foto,
.btn-baixar-foto {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.clickable {
  cursor: pointer;
}
</style>
