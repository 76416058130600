import { createShopPaymentPix } from '@/services/shop';
import axios from 'axios'
function gerarIdUnico() {
  const prefixo = "SOL";
  const tempoAtual = new Date().getTime().toString(36); // Convertendo o timestamp em base36
  const aleatorio = Math.random().toString(36).substring(2, 8); // Substring para manter no máximo 6 caracteres

  return prefixo + tempoAtual + aleatorio;
}
const state = {
  modalEnderecoVisivel: false,
  modalEnderecoIntVisivel: false,
  modalEnderecoByPositionVisivel: false,
  cartVisivel: false,
  activeStepComponent: 'StepChoiceType',
  activeStepChoiceTypeComponent: 'StepChoiceTypeDelivery',
  resumoPedido: {
    valorProdutos: 0,
    frete: {
      active: true,
      value: 0
    },
    descontoPagamento: {
      active: false,
      value: 0
    },
    total: 0,
    freteGratis: {
      value: 300
    },
    tipo: {
      retirada: {
        active: false,
        date: null,
        day: null,
        period: null,
        endereco: null

      },
      entrega: {
        active: true,
        date: null,
        day: null,
        period: null,
        endereco: null
      }
    },
    coupon: {
      active: false,
      code: "",
      discount: 0,
      value: 0
    }
  },
  enderecoSelecionado: null,
  enderecoByPosition: {},
  enderecoModalInt: {},
  enderecoSelecionadoIndex: null,
  origem: "R. Álvaro Antônio Sauka, 10 - Olaria, Rio de Janeiro - RJ",
  enderecoOrigem: {
    cep: "21073-100",
    endereco: "R. Álvaro Antônio Sauka",
    numero: "10",
    complemento: "",
    bairro: "Olaria",
    cidade: "Rio de Janeiro",
    estado: "RJ"
  },
  pixChosenPayment: {
    qrCodeURL: '',
    pixCode: '',
  },
  checkoutTotal: 0,
  selectedIndexPaymentMethod: 0,
  paymentMethods: [
    /*     {
          id: 1,
          value: "cash",
          name: "formaDePagamento",
          iconSrc: "/assets/img/icon-dinheiro.svg",
          iconAlt: "Dinheiro",
          title: "Dinheiro",
          subtitle: "Pague em dinheiro",
          text: "Desconto de 5.00%",
          discount: 0.05
    
        }, */
    {
      id: 2,
      value: "pix",
      name: "formaDePagamento",
      iconSrc: "/assets/img/checkout/svg/icon-pay-pix.svg",
      iconAlt: "PIX",
      title: "PIX",
      subtitle: "Pague com PIX",
      text: "Desconto de 5%",
      discount: {
        active: true,
        value: 0.05
      }
    },
    /*   {
        id: 3,
        value: "creditCard",
        name: "formaDePagamento",
        iconSrc: "/assets/img/pagamento/icon-master.svg",
        iconAlt: "Cartão de Crédito",
        title: "Cartão de Crédito",
        subtitle: "Pague com cartão de crédito",
        text: "Sem desconto",
        discount: 0
      } */
  ],
  selectedPaymentMethod: null,
  selectedPeriod: 0,
  periodosEntrega: [
    { description: '- - - -', title: '- - - - - - - - -' },
    { title: 'Período entre 09', description: '09:00h às 13:00h', value: '09:00 / 13:00' },
    { title: 'Período entre 13', description: '13:00h às 17:00h', value: '13:00 / 17:00' },
    { title: 'Período entre 17', description: '17:00h às 21:00h', value: '17:00 / 21:00' }
  ],
  cupomValido: null,
  dateSelected: {
    date: {
      day: null,
      date: null
    },
    day: null,
    month: null,
    year: null
  },
  isButtonDisabledNextInCheckoutStepChoiceType: true,
  isButtonDisabledNextInCheckoutStepShedule: true,
  pixImageShow: false,
  orderNumber: null
};

const mutations = {
  ABRIR_MODAL_ENDERECO(state) {
    state.modalEnderecoVisivel = true;
  },
  FECHAR_MODAL_ENDERECO(state) {
    state.modalEnderecoVisivel = false;
  },
  ABRIR_CART(state) {
    state.cartVisivel = true;
  },
  FECHAR_CART(state) {
    state.cartVisivel = false;
  },
  ABRIR_MODAL_ENDERECO_INT(state) {
    state.modalEnderecoIntVisivel = true;
  },
  FECHAR_MODAL_ENDERECO_INT(state) {
    state.modalEnderecoIntVisivel = false;
  },
  ABRIR_MODAL_ENDERECO_BY_POSITION(state) {
    state.modalEnderecoIntVisivel = true;
  },
  FECHAR_MODAL_ENDERECO_BY_POSITION(state) {
    state.modalEnderecoIntVisivel = false;
  },
  FECHAR_TODOS_MODAIS(state) {
    state.modalEnderecoVisivel = false;
    state.modalEnderecoIntVisivel = false;
  },
  SET_ACTIVE_STEP_COMPONENT(state, componentName) {
    try {
      switch (componentName) {
        case 'StepShedule': {
          state.activeStepComponent = componentName;
          break;
        }
        case 'StepPixChosen': {
          state.activeStepComponent = componentName;
          break;
        }
        case 'StepPaymentForm': {
          break;
        }

      }
      state.activeStepComponent = componentName;
    } catch (error) {
      console.error('Erro ao calcular o frete:', error);

    }
  },
  SET_PIX_DATA(state, dadosPix) {
    console.log({ dadosPix })
    state.pixChosenPayment = dadosPix;
  },
  SET_PICKUP() { },
  SET_DELIVERY() { },
  SET_ACTIVE_STEP_CHOICE_TYPE_COMPONENT(state, componentName) {
    switch (componentName) {
      case 'StepChoiceTypeDelivery': {
        state.resumoPedido.tipo.entrega.active = true
        state.resumoPedido.tipo.retirada = {
          active: false,
          date: '',
          day: '',
          period: '',
          endereco: ""

        }
        break;
      }
      case 'StepChoiceTypePickup': {
        state.resumoPedido.frete.value = 0;
        state.resumoPedido.tipo.retirada.active = true
        state.resumoPedido.tipo.entrega = {
          active: false,
          date: '',
          day: '',
          period: '',
          endereco: ""
        }
        break;
      }
    }
    state.activeStepChoiceTypeComponent = componentName;
  },
  async SET_ACTIVE_STEP_CHOICE_TYPE_COMPONENT2(state, componentName) {
    try {
      switch (componentName) {
        case 'StepChoiceTypeDelivery': {
          const { endereco, numero, bairro, cidade, estado } = state.enderecos[state.enderecoSelecionadoIndex];
          const destino = `${endereco}, ${numero} - ${bairro}, ${cidade} - ${estado}`;

          const response = await axios.post('https://conecta-se.online/calcular-frete', {
            origem: state.origem,
            destino
          });

          state.resumoPedido.frete.value = response.data.custoFrete;
          state.resumoPedido.tipo.entrega.endereco = destino
          state.resumoPedido.tipo.entrega.active = true

          state.resumoPedido.tipo.retirada.active = false
          break;
        }
        case 'StepChoiceTypePickup': {
          state.resumoPedido.frete.value = 0;
          state.resumoPedido.tipo.retirada.active = true
          state.resumoPedido.tipo.entrega.active = false

          break;
        }
      }

      state.activeStepChoiceTypeComponent = componentName;
    } catch (error) {
      console.error('Erro ao calcular o frete:', error);
    }
  },
  SET_SHIPPING_DATA(state, data) {
    state.resumoPedido.frete.value = data.custoFrete;
    state.resumoPedido.tipo.entrega.endereco = data.destino
  },
  ADICIONAR_ENDERECO(state, novoEndereco) {
    state.enderecos.push(novoEndereco);
  },
  SELECIONAR_ENDERECO(state, address) {
    const { endereco, numero, bairro, cidade, estado } = address
    const destino = `${endereco}, ${numero} - ${bairro}, ${cidade} - ${estado}`;
    state.resumoPedido.tipo.entrega.endereco = destino
    alert(state.resumoPedido.tipo.entrega.endereco)
  },
  UPDATE_CHECKOUT_TOTAL(state, total) {
    state.resumoPedido.valorProdutos = total;
  },
  SET_SELECTED_PAYMENT_METHOD(state, paymentMethodIndex) {
    state.selectedIndexPaymentMethod = paymentMethodIndex
    const paymentMethod = state.paymentMethods[paymentMethodIndex]
    console.log({
      paymentMethod
    })
    state.selectedPaymentMethod = paymentMethod;
    const discontValue = paymentMethod.discount.active ? paymentMethod.discount.value : 0

    const total = state.resumoPedido.valorProdutos + state.resumoPedido.frete.value
    const diff = total * (1 - discontValue)
    state.resumoPedido.descontoPagamento.value = diff - total;
    state.resumoPedido.descontoPagamento.active = paymentMethod.discount.active ? true : false

  },
  SET_DATE_DELIVERY(state, { date, day }) {
    state.resumoPedido.tipo.entrega.date = date
    state.resumoPedido.tipo.entrega.day = day
  },
  SET_PERIOD_DELIVERY(state, period) {
    state.resumoPedido.tipo.entrega.period = period
  },
  SET_DATE_PICK_UP(state, { date, day }) {
    state.resumoPedido.tipo.retirada.date = date
    state.resumoPedido.tipo.retirada.day = day
  },
  SET_PERIOD_PICK_UP(state, period) {
    state.resumoPedido.tipo.retirada.period = period
  },
  SET_ADDRESS_PICK_UP(state) {
    state.resumoPedido.tipo.retirada.endereco = state.origem
  },
  SET_PERIOD(state, index) {
    state.selectedPeriod = index
  },
  SET_CUPOM_VALIDO(state, valido) {
    state.cupomValido = valido;
  },
  SET_CUPOM_SUMARY(state, coupon) {
    const discount = coupon.discount / 100
    const total = state.resumoPedido.valorProdutos
    const diff = total * (1 - discount)
    state.resumoPedido.coupon = {
      active: true,
      code: coupon.code,
      discount,
      value: diff - total
    }
  },
  SET_ENDERECO_MODAL_INT(state, endereco) {
    state.enderecoModalInt = endereco
  },
  SET_ENDERECO_BY_POSITION(state, endereco) {
    state.enderecoByPosition = endereco
  },
  RESET_CHECKOUT(state) {

    state.modalEnderecoVisivel = false
    state.modalEnderecoIntVisivel = false
    state.modalEnderecoByPositionVisivel = false
    state.cartVisivel = false
    state.activeStepComponent = 'StepChoiceType'
    state.activeStepChoiceTypeComponent = 'StepChoiceTypeDelivery'
    state.resumoPedido = {
      valorProdutos: 0,
      frete: {
        active: true,
        value: 0
      },
      descontoPagamento: {
        active: false,
        value: 0
      },
      total: 0,
      freteGratis: {
        value: 300
      },
      tipo: {
        retirada: {
          active: false,
          date: null,
          day: null,
          period: null,
          endereco: null

        },
        entrega: {
          active: false,
          date: null,
          day: null,
          period: null,
          endereco: null
        }
      },
      coupon: {
        active: false,
        code: "",
        discount: 0,
        value: 0
      }
    }
    state.enderecos = []
    state.enderecoSelecionado = null
    state.enderecoByPosition = {}
    state.enderecoModalInt = {}
    state.enderecoSelecionadoIndex = 0
    state.origem = "R. Álvaro Antônio Sauka - Olaria, Rio de Janeiro - RJ"
    state.pixChosenPayment = {
      qrCodeURL: null,
      pixCode: null,
      startDate: null,
      endDate: null
    }
    state.checkoutTotal = 0,
      state.paymentMethods = [

        {
          id: 2,
          value: "pix",
          name: "formaDePagamento",
          iconSrc: "/assets/img/checkout/svg/icon-pay-pix.svg",
          iconAlt: "PIX",
          title: "PIX",
          subtitle: "Pague com PIX",
          text: "Desconto de 3.00%",
          discount: 0.05
        }
      ]
    state.selectedPaymentMethod = null
    state.selectedPeriod = 0
    state.periodosEntrega = [
      { description: '- - - -', title: '- - - - - - - - -' },
      { title: 'Período entre 09', description: '09:00h às 13:00h', value: '09:00 / 13:00' },
      { title: 'Período entre 13', description: '13:00h às 17:00h', value: '13:00 / 17:00' },
      { title: 'Período entre 17', description: '17:00h às 21:00h', value: '17:00 / 21:00' }
    ]
    state.cupomValido = null
    state.dateSelected = {
      date: {
        day: null,
        date: null
      },
      day: null,
      month: null,
      year: null
    }
    state.orderNumber
  },
  SET_ADDRESS_SELECTED(state, address) {
    state.enderecoSelecionado = address
  },
  SET_DATE_SELECTED(state, date) {
    state.dateSelected = date
  },
  SET_PIX_IMAGE_SHOW(state, isValid) {
    state.pixImageShow = isValid
  },
  SET_ORDER_NUMBER(state, orderNumber) {
    state.orderNumber = orderNumber
  }
};

function addTimeToDate(isoDate, timeToAdd) {
  const date = new Date(isoDate);
  const newDate = new Date(date.getTime() + timeToAdd);
  return newDate.toISOString();
}

const actions = {
  abrirModalEndereco({ commit }) {
    commit('ABRIR_MODAL_ENDERECO');
  },
  fecharModalEndereco({ commit }) {
    commit('FECHAR_MODAL_ENDERECO');
  },
  abrirCart({ commit }) {
    commit('ABRIR_CART');
  },
  fecharCart({ commit }) {
    commit('FECHAR_CART');
  },
  abrirModalEnderecoInt({ commit }) {
    commit('ABRIR_MODAL_ENDERECO_INT');
  },
  fecharModalEnderecoByPosition({ commit }) {
    commit('FECHAR_MODAL_ENDERECO_BY_POSITION');
  },
  abrirModalEnderecoByPosition({ commit }) {
    commit('ABRIR_MODAL_ENDERECO_BY_POSITION');
  },
  fecharModalEnderecoInt({ commit }) {
    commit('FECHAR_MODAL_ENDERECO_INT');
  },
  fecharTodosModais({ commit }) {
    commit('FECHAR_TODOS_MODAIS');
  },
  setActiveStepComponent({ commit }, componentName) {
    commit('SET_ACTIVE_STEP_COMPONENT', componentName);
  },
  async setActiveStepChoiceTypeComponent({ commit }, componentName) {

    commit('SET_ACTIVE_STEP_CHOICE_TYPE_COMPONENT', componentName);
    switch (componentName) {
      case 'StepChoiceTypeDelivery': {
        //dispatch('checkout/calculateShipping', null, { root: true })
        break;
      }
      case 'StepChoiceTypePickup': {
        commit('SET_ADDRESS_PICK_UP')
        break;
      }
    }
  },
  async calculateShipping({ commit, state }, address) {
    try {
      const { endereco, numero, bairro, cidade, estado } = address;
      const destino = `${endereco}, ${numero} - ${bairro}, ${cidade} - ${estado}`;

      const response = await axios.post('https://conecta-se.online/calcular-frete', {
        origem: state.origem,
        destino
      })
      commit('SET_SHIPPING_DATA', {
        destino,
        custoFrete: response.data.custoFrete
      })
    } catch (error) {
      console.error('Error ao calcular frete', error)
    }
  },
  async gerarPix({ commit, dispatch, rootGetters }, router) {
    try {
      const id = gerarIdUnico()
      const numero = gerarIdUnico()
      commit('SET_ORDER_NUMBER', numero)
      const response = await axios.post('https://api.donasolajuda.com/payments/pix', {
        valor: rootGetters['checkout/getTotalCheckout'] + "",
      });
      console.log({
        "response.data": response.data
      })
      const dateNow = new Date().toLocaleDateString()
      const currentDate = new Date();
      const options = { timeZone: 'America/Sao_Paulo' };
      const startDate = currentDate.toLocaleString('en-US', options);

      const timeToAdd = 1 * 60 * 60 * 1000;
      const endDate = addTimeToDate(startDate, timeToAdd);

      commit('SET_PIX_DATA', {
        qrCodeURL: response.data.qrcodeImage,
        pixCode: response.data.copieecola,
        startDate: startDate,
        endDate: endDate
      });
      const orderUser = {
        id: id,
        orderId: id,
        numero: numero,
        data: dateNow,
        valor: rootGetters['checkout/formattedTotalCheckout'],
        formaPagamento: rootGetters['checkout/getPaymentForm'],
        status: `Aguardando Pagamento (${dateNow})`
      }

      dispatch('user/addOrderUser',
        orderUser,
        { root: true })

      dispatch('user/addOrderDetailsUser',
        {
          orderId: id,
          pedido: {
            numero: "#" + numero,
            status: "Aguardando Pagamento"
          },
          registro1: {
            dataNovo: dateNow,
            dataPendente: dateNow,
            dataPago: '',
            dataEnviado: '',
            dataEntregue: '',
            dataCancelado: ''
          },
          registro2: {
            data: rootGetters['checkout/getDate'],//data da entrega ou retirada
            periodo: rootGetters['checkout/getPeriod'],
            nome: rootGetters['auth_google/currentUser'].displayName,
            endereco: `${rootGetters['checkout/getAddress'].endereco}, ${rootGetters['checkout/getAddress'].numero}, ${rootGetters['checkout/getAddress'].complemento} - ${rootGetters['checkout/getAddress'].bairro}`,
            cep: rootGetters['checkout/getAddress'].cep,
            localidade: rootGetters['checkout/getAddress'].cidade,
            uf: rootGetters['checkout/getAddress'].estado,
            tipoPagamento: rootGetters['checkout/getPaymentForm'],
            dataPagamento: dateNow,
            statusPagamento: 'transação aguardando pagamento (pix).',
            qrCodeLink: response.data.copieecola
          },
          registro3: {
            itens: rootGetters['cart/getCartItensToCheckout']
          },
          registro4: {
            subtotal: rootGetters['checkout/formattedValorProdutos'],
            frete: rootGetters['checkout/formattedFrete'],
            desconto: rootGetters['checkout/getTotalDesconto'],
            totalGeral: rootGetters['checkout/formattedTotalCheckout']
          }
        },
        { root: true })

      const { txid, valor, calendario } = response.data.charge
      await createShopPaymentPix('1', txid, {
        txid,
        valor,
        status: {
          txt: "aguardando pagamento",
          value: 'aguardando pagamento',
          data: dateNow
        },
        pago: false,
        calendario,
        cliente: rootGetters['auth_google/currentUser'],
        order: {
          orderId: orderUser.id,
          ...orderUser,
          status:{
            value: 'aguardando pagamento'
          },
          quantityProducts: rootGetters['cart/getCartItensToCheckout'].length,
          endereco: `${rootGetters['checkout/getAddress'].endereco}, ${rootGetters['checkout/getAddress'].numero}, ${rootGetters['checkout/getAddress'].complemento} - ${rootGetters['checkout/getAddress'].bairro}`,
        },
        orderId: orderUser.id,
        

      })

    } catch (error) {
      router.push({})
      console.error('Erro ao gerar pix:', error);
    }
  },
  adicionarEndereco({ dispatch }, novoEndereco) {
    try {
      dispatch('user/addAddress', novoEndereco, { root: true })
    } catch (error) {
      console.error(error)
    }
  },
  selectAddressByAddressId({ commit, dispatch, rootGetters }, addressId) {
    dispatch('user/setAddressByAddressId', addressId, { root: true })
    const address = rootGetters['user/getAddressSelected']
    console.log({
      address
    })
    commit('SELECIONAR_ENDERECO', address);
    commit('SET_ADDRESS_SELECTED', address);
    dispatch('checkout/calculateShipping', address, { root: true })

  },
  updateCheckoutTotal({ commit, rootGetters }) {
    const total = rootGetters['cart/getSubtotal'];
    commit('UPDATE_CHECKOUT_TOTAL', total);
  },
  selectPaymentMethod({ commit }, paymentMethod) {
    commit('SET_SELECTED_PAYMENT_METHOD', paymentMethod);
  },
  setDate({ commit }, date) {
    commit('SET_DATE_DELIVERY', date);
    commit('SET_DATE_PICK_UP', date);
    const parts = date.date.split('/');
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1; // Os meses são indexados de 0 a 11 no JavaScript
    const year = parseInt(parts[2], 10);
    commit('SET_DATE_SELECTED', {
      date,
      day,
      month,
      year
    });
  },
  setPeriodDelivery({ commit }, period) {
    commit('SET_PERIOD_DELIVERY', period);
  },
  resetCheckout({ commit, dispatch }) {
    commit('RESET_CHECKOUT')
    dispatch('cart/resetCart', null, { root: true })
  },
  setPeriod({ commit, state }, selectedPeriod) {
    if (selectedPeriod === '0') {
      commit('SET_PERIOD_DELIVERY', "");
      commit('SET_PERIOD_PICK_UP', "");
    } else {
      console.log({
        selectedPeriod
      })
      const { description } = state.periodosEntrega[selectedPeriod]

      commit('SET_PERIOD', selectedPeriod);
      commit('SET_PERIOD_DELIVERY', description);
      commit('SET_PERIOD_PICK_UP', description);
    }
  },
  async aplicarCupom({ commit }, coupon) {
    try {
      const response = await axios.post('https://api.donasolajuda.com/payments/discount-coupon', {
        coupon
      });

      const data = response.data;
      commit('SET_CUPOM_SUMARY', data.coupon);
      commit('SET_CUPOM_VALIDO', true);
    } catch (error) {
      commit('SET_CUPOM_VALIDO', false);
      console.error('Erro ao aplicar cupom:', error);
    }
  },
  async getAdressByPosition({ commit }, position) {
    try {
      const response = await axios.post('https://conecta-se.online/geocode', position);

      const endereco = response.data;
      console.log(endereco)
      commit('SET_ENDERECO_MODAL_INT', endereco);
      commit('ABRIR_MODAL_ENDERECO_INT');
    } catch (error) {
      console.error('Erro ao pegar o endereço:', error);
    }
  },
  async pesquisarCEP({ commit }, cep) {
    try {
      const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
      const data = response.data;
      const endereco = {}
      endereco.cep = cep
      endereco.endereco = data.logradouro || '';
      endereco.complemento = data.complemento || '';
      endereco.bairro = data.bairro || '';
      endereco.cidade = data.localidade || '';
      endereco.estado = data.uf || '';
      commit('SET_ENDERECO_MODAL_INT', endereco);
      console.log(endereco)
    } catch (error) {
      console.error('Erro ao buscar CEP:', error);
    }
  },
  getAddressesIfToExist({ dispatch }) {
    dispatch('user/getAddressesUser', null, { root: true })
  },

};

const getters = {
  formattedValorProdutos: state => {
    return state.resumoPedido.valorProdutos.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  },
  formattedFrete: state => {
    return state.resumoPedido.frete.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  },
  formattedDescontoPagamento: state => {
    return state.resumoPedido.descontoPagamento.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  },
  formattedDescontoCupom: state => {
    return state.resumoPedido.coupon.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  },
  formattedDescontoFreteGratis: state => {
    const value = state.resumoPedido.freteGratis.value - state.resumoPedido.valorProdutos
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  },
  formattedTotal: state => {
    return state.resumoPedido.total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  },
  formattedTotalCheckout: state => {
    const resumoPedido = [
      state.resumoPedido.valorProdutos,
      state.resumoPedido.frete.value,
      state.resumoPedido.descontoPagamento.value,
      state.resumoPedido.coupon.value,
    ]
    console.log(resumoPedido)
    const total = resumoPedido.reduce((a, b) => a + b, 0)
    return total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  },
  getTotalCheckout: state => {
    const resumoPedido = [
      state.resumoPedido.valorProdutos,
      state.resumoPedido.frete.value,
      state.resumoPedido.descontoPagamento.value,
      state.resumoPedido.coupon.value,
    ]
    console.log(resumoPedido)
    const total = resumoPedido.reduce((a, b) => a + b, 0)
    return total.toFixed(2)
  },
  getTotalDesconto: state => {
    const resumoPedido = [
      state.resumoPedido.descontoPagamento.value,
      state.resumoPedido.coupon.value,
    ]
    const total = resumoPedido.reduce((a, b) => a + b, 0)
    return total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  },
  pegarEnderecoSelecionado: state => {
    return state.enderecoSelecionado
  },
  getPaymentForm: state => {
    const { title, text } = state.selectedPaymentMethod
    return `${title} - ${text}`
  },
  verifyIfIsButtonDisabledNextInCheckoutStepChoiceType: state => {
    if (state.enderecoSelecionado || state.resumoPedido.tipo.retirada.active) {
      return false
    } else {
      return true
    }
  },
  verifyIfIsButtonDisabledNextInCheckoutStepShedule: state => {
    if (
      (state.resumoPedido.tipo.retirada.date && (state.selectedPeriod != 0))
      || state.resumoPedido.tipo.entrega.date && (state.selectedPeriod != 0)) {
      return false
    } else {
      return true
    }
  },
  getImagePix: state => {
    return state.pixChosenPayment.qrCodeURL
  },
  getPixChosenPaymentExpiredDate: state => {
    const { startDate, endDate } = state.pixChosenPayment
    if (startDate && endDate) {
      return {
        startDate,
        endDate
      }
    } else {
      return false
    }
  },
  getPixCode: state => {
    const { pixCode } = state.pixChosenPayment
    return pixCode
  },
  getOrderNumber: state => {
    return state.orderNumber
  },
  getPeriod: state => {
    if (state.resumoPedido.tipo.retirada.active) {
      return state.resumoPedido.tipo.retirada.period
    }
    if (state.resumoPedido.tipo.entrega.active) {
      return state.resumoPedido.tipo.entrega.period
    }
  },
  getDate: state => {
    if (state.resumoPedido.tipo.retirada.active) {
      return state.resumoPedido.tipo.retirada.date
    }
    if (state.resumoPedido.tipo.entrega.active) {
      return state.resumoPedido.tipo.entrega.date
    }
  },
  getAddress: state => {
    if (state.resumoPedido.tipo.retirada.active) {
      return state.enderecoOrigem
    }
    if (state.resumoPedido.tipo.entrega.active) {
      return state.enderecoSelecionado
    }
  }

}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
