<template>
    <MainLayout>
        <section class="container">
            <div class="row" v-if="categoriesTest">
                <MenuCategoriesHorizontal />
                <!-- <BannerComponent /> -->

            </div>
        </section>

        <div class="box-order-mobile">
            <InfoComponent />
            <section class="vitrine-home order">
                <div class="container">
                    <div class="row">
                        <h1 class="seo sr-only">Deseja Comprar Comida Congelada? Conheça a Dona Sol Ajuda Congelados
                            Especiais! A
                            Melhor Solução em Comidas Prontas Congeladas em Rio de Janeiro.</h1>
                        <div class="col-xs-12" v-if="getCategoriesT.length">
                            <article class="" v-for="category in getCategoriesT" :key="category.categoryId">
                                <ShowcaseComponent :infoTitle="{ ...category, products: undefined }"
                                    :products="category.products || []" />
                            </article>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <!-- Nosso conceito -->

       <!--  <ConceptComponent title="A DONA SOL AJUDA CONGELADOS"
            subtitle="A Dona Sol Ajuda Congelados é uma Marca de Comida Congelada para Pronto-Consumo."
            description="Nossa Fábrica está localizada em Olria e conta com equipamentos de última geração e equipe qualificada que segue rígidos padrões de qualidade na produção dos congelados. Temos a solução completa em Refeições Prontas Congeladas que atenderão plenamente a sua necessidade." />
 -->
        <!-- Depoimentos -->
    </MainLayout>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import MainLayout from '@/layouts/MainLayout.vue';
import MenuCategoriesHorizontal from '@/components/MenuCategoriesHorizontal.vue';
// import ConceptComponent from '@/components/ConceptComponent.vue';
import InfoComponent from '@/components/InfoComponent.vue'
import ShowcaseComponent from '@/components/ShowcaseComponent.vue'

export default {
    components: {
        MainLayout,
        InfoComponent,
        // ConceptComponent,
        ShowcaseComponent,
        MenuCategoriesHorizontal
    },
    computed: {
        ...mapState('shop', ['categories', 'initalDataShop', 'categoriesTest']), // Corrigido o nome de 'initalDataShop' para 'initalDataShop'
        ...mapGetters('shop', ['getCategoriesT'])
    },
    methods: {
        ...mapActions('shop', ['initialShop', 'getCategories', 'loadMisingProduct'])
    },
    async created() {
        try {
            await this.getCategories()
           // await this.initialShop()
            console.log("axé") // Corrigido para acessar 'initalDataShop' corretamente
            await this.loadMisingProduct()
        } catch (error) {
            console.error(error)
        }
    },
    async mounted() {
    }
}
</script>
