<template>
    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-5 box-desc box-produto-interno">

        <div style="display: flex !important;;">
            <h1 class="nome-produto" style="word-wrap: break-word; max-width: 90%;">{{ productIn?.name }}</h1>
            <!-- FAVORITOS -->
            <span class="box-favorito clickable" @click="handleClick" style="top: -10px;">
                <i :id="'tst-' + productIn.productId"
                    :class="{ 'fa fa-heart': isFavorite, 'fa fa-heart-o': !isFavorite }"></i>
            </span>
        </div>
        <!-- 
        

        <p class="info">Cód. Barras:
            <span class="produto-codigo">
                PRD00288
            </span>
        </p>
        <p class="info">|</p>
        <p class="info"><span class="kcal">504</span> calorias</p>
 -->
        <!-- Se o produto for de Consulta -->

        <div class="desc">
            <p><b>{{ productIn?.description }} </b></p>
            <!-- 
            <p>
                <meta charset="utf-8">
            </p> -->

            <p dir="ltr">&nbsp;</p>
        </div>

        <div class="group-selos">
            <span v-for="(seal, index) in productIn?.seals" :key="index" class="item-selo" data-toggle="tooltip"
                data-placement="top" :title="seal">
                <picture>
                    <img :src="seal.image" :alt="seal.title" />
                </picture>
            </span>
        </div>

        <!-- Mais cores -->

        <!-- Midias -->

        <!-- Guia de Medidas -->


        <div class="escolher">
            <div class="tipo">
                <select class="form-control" v-model="measurementsOptionsSelected">
                    <option v-for="(option, index) in productIn?.measurementsOptions" :value="option" :key="index"
                        :selected="option.selected">
                        {{ option.text }}
                    </option>
                </select>
            </div>
            <div class="quantidade">
                <div class="box-number">
                    <div class="add-qtd" @click="minus"><i class="fa fa-minus"><span>-</span></i></div>
                    <input type="text" name="quantidade" class="qtd" v-model="quantity" />
                    <div class="add-qtd" @click="plus"><i class="fa fa-plus"><span>+</span></i></div>
                </div>
            </div>
        </div>
        <div class="group-valor" v-if="!productIn.price.discount.active">
            <div class="js-valor js-desconto valor valor-desconto new-price 2">{{ getProductPrice }}
            </div>
        </div>
        <div class="group-valor" v-else>
            <div class="js-valor-desc valor old-price">{{ getDiscountInPercentege }} OFF</div>
            <div class="js-valor valor old-price">de <span>{{ getProductPrice }}</span></div>
            <div class="js-desconto valor-desconto new-price 1">por {{ getValueProductWithDiscount }}
            </div>
        </div>




        <div class="box-adicionar adicionar clickable">
            <span @click="buy">COMPRAR</span>
        </div>





        <!-- FIM FAVORITOS -->




        <!-- Calcular Frete -->
        <!--


         <CalculateShipping />
       -->

    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
//import CalculateShipping from '@/components/DetailsProductPage/CalculateShipping.vue';


export default {
    components: {

    },
    props: {
        product: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            quantity: 1,
            measurementsOptionsSelected: null,
            isFavorite: false,
            productIn: null,
            toastVisible: false,
            description: "Hoje é quarta feira"
        };
    },
    async beforeMount() {
        console.log('load-product');
        await this.initProduct();
    },
    watch: {
        measurementsOptionsSelected(productIn) {
            try {
                this.productIn = {
                    ...this.productIn,
                    ...productIn,
                    selected: true
                };
            } catch (error) {
                console.error(error)
            }
        },
        product() {
            this.initProduct()
        },
    },
    computed: {
        getProductPrice() {
            return this.productIn ? this.productIn.price.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : '';
        },
        getDiscountInPercentege() {
            return this.productIn ? this.floatToPercentage(this.productIn.price.discount.value) : '';
        },
        getValueProductWithDiscount() {
            return this.productIn ? this.applyDiscountProductPrice() : '';
        },
        ...mapGetters('user', ['getLikes']),
    },
    methods: {
        ...mapActions('cart', ['addProduct']),
        ...mapActions('user', ['removeLike', 'addLike']),
        minus() {
            if (this.quantity > 1) {
                this.quantity--;
            }
        },
        plus() {
            this.quantity++;
        },
        buy() {
            const product = { ...this.productIn, quantity: this.quantity };
            this.addProduct(product);
            this.quantity = 1;
        },
        handleClick() {
            if (this.$store.state.auth.isLoggedIn) {
                this.likeAction();
            } else {
                this.$routerproductIn.push('/login');
            }
        },
        async likeAction() {
            try {
                const likeConfig = {
                    like: {
                        productId: this.productIn.productId,
                        productSameId: this.productIn.productSameId,
                        categoryId: this.productIn.categoryId
                    },
                    router: this.$router
                };
                if (!this.isFavorite) {
                    await this.addLike(likeConfig);
                    this.isFavorite = true;
                } else {
                    await this.removeLike(likeConfig);
                    this.isFavorite = false;
                }
            } catch (error) {
                console.error(error);
            }
        },
        floatToPercentage(num) {
            const rounded = Math.round(num * 100);
            return rounded + "%";
        },
        applyDiscountProductPrice() {
            if (this.productIn.price.discount.active) {
                const precoAtual = this.productIn.price.value;
                const novoPreco = precoAtual * (1 - this.productIn.price.discount.value);
                return novoPreco.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
            }
            return this.productIn.price;
        },
        initProduct() {
            const productSame = this.product;
            const productSelectedId = productSame.selectedProduct;

            const productSelected = productSame.measurementsOptions.find(product => product.productId === productSelectedId);
            this.productIn = { ...productSelected, ...productSame };
            this.measurementsOptionsSelected = productSelected;
            const exist = this.getLikes.find(like => like.productSameId === this.productIn.productSameId);
            this.isFavorite = !!exist;
            console.log({ productSelectedId, productSame, box: true, productIn: this.productIn });
        }

    }
};
</script>
