<template>
    <div class="dsac-col-12 dsac-col-xl-5 dsac-mb-4 dsac-mb-xl-0">
        <div class="dsac-card-white dsac-mod-msg">
            <h4 class="dsac-mb-1">Detalhes do pedido</h4>
            <div class="dsac-card-white-list">
                <h5 class="dsac-pe-sm-4">Número do pedido</h5>
                <h5 class="dsac-text-sm-end"><strong>{{getOrderNumber}}</strong></h5>
            </div>
            <div class="dsac-card-white-list">
                <h5 class="dsac-pe-sm-4">Valor total</h5>
                <h5 class="dsac-text-sm-end"><strong>{{ formattedTotalCheckout }}</strong></h5>
            </div>
            <div class="dsac-card-white-list">
                <h5 class="dsac-pe-sm-4">Forma de pagamento</h5>
                <h5 class="dsac-text-sm-end"><strong>{{ getPaymentForm }}</strong></h5>
            </div>
            <div class="dsac-card-white-list" v-if="resumoPedido.tipo.entrega.active">
                <h5 class="dsac-pe-sm-4">Forma de Entrega</h5>
                <h5 class="dsac-text-sm-end"><strong>Dona Sol Ajuda</strong></h5>
            </div>
            <div class="dsac-card-white-list" v-if="resumoPedido.tipo.retirada.active">
                <h5 class="dsac-pe-sm-4">Forma de Entrega</h5>
                <h5 class="dsac-text-sm-end"><strong>{{ origem }}</strong></h5>
            </div>
            <div class="dsac-card-white-list" v-if="resumoPedido.tipo.entrega.active">
                <h5 class="dsac-pe-sm-4">Endereço</h5>
                <h5 class="dsac-text-sm-end"><strong>{{ resumoPedido.tipo.entrega.endereco }}</strong></h5>
            </div>
            <div class="dsac-card-white-list" v-if="resumoPedido.tipo.retirada.active">
                <h5 class="dsac-pe-sm-4">Endereço</h5>
                <h5 class="dsac-text-sm-end"><strong>{{ resumoPedido.tipo.retirada.endereco }}</strong></h5>
            </div>
            <div class="dsac-alert dsac-text-center dsac-mt-2 dsac-mt-sm-3" v-if="resumoPedido.tipo.entrega.active">Sua
                entrega
                foi agendada para o dia <strong>{{ resumoPedido.tipo.entrega.date }}
                    {{ resumoPedido.tipo.entrega.day }}</strong> no período das
                <strong>{{ resumoPedido.tipo.entrega.period }}</strong>
            </div>
            <div class="dsac-alert dsac-text-center dsac-mt-2 dsac-mt-sm-3" v-if="resumoPedido.tipo.retirada.active">Sua
                retirada
                foi agendada para o dia <strong>{{ resumoPedido.tipo.retirada.date }}
                    {{ resumoPedido.tipo.retirada.day }}</strong> no período das
                <strong>{{ resumoPedido.tipo.retirada.period }}</strong>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
export default {
    computed: {
        ...mapState('checkout', ['resumoPedido', 'origem', 'selectedPaymentMethod']),
        ...mapGetters('checkout', [
            'formattedValorProdutos',
            'formattedFrete',
            'formattedDescontoPagamento',
            'formattedTotal',
            'formattedTotalCheckout',
            'formattedDescontoFreteGratis',
            'formattedDescontoCupom',
            'getPaymentForm',
            'getOrderNumber'
        ]),
    }
};
</script>
