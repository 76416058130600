<template>
    <div class="item col-xs-6 col-sm-4">
        <div class="box-produto js-box-produto  max-height" :id="'prod-' + productIn.productId">
            <div class="img-destaque" v-if="productIn.tag.active">
                <picture>
                    <img class="img-responsive" :src="productIn.tag.image" :alt="productIn.tag.description"
                        :title="productIn.tag.title" />
                </picture>
            </div>

            <span class="box-favorito clickable" @click="handleClick">
                <i :id="'tst-' + productIn.productId"
                    :class="{ 'fa fa-heart': isFavorite, 'fa fa-heart-o': !isFavorite }"></i>
            </span>

            <router-link class="img-hover-vitrine" :to="productIn.link">
                <picture>
                    <source class="js-lazy-source" :srcset="productIn.lazySrcset" :data-srcset="productIn.dataSrcset"
                        type="image/webp" />
                    <img class="img-responsive img-capa js-lazy-img" :src="productIn.lazyImg"
                        :data-src="productIn.dataSrc" :alt="productIn.name" :title="productIn.name" />
                </picture>
            </router-link>

            <div class="box-descricao">
                <div class="box-left js-h-card">
                    <router-link :to="productIn.link">
                        <h5 class="nome-produto fixed-height">{{ productIn.name }}</h5>
                    </router-link>
                    <!-- Trustvox -->
                </div>

                <div class="group-selos">
                    <span v-for="(seal, index) in productIn.seals" :key="index" class="item-selo" data-toggle="tooltip"
                        data-placement="top" :title="seal">
                        <picture>
                            <img :src="seal.image" :alt="seal.title" />
                        </picture>
                    </span>
                </div>

                <div class="box-right js-h-card">
                    <div class="group-int">
                        <div class="escolher">
                            <div class="tipo">
                                <select name="gramas" class="form-control" v-model="measurementsOptionsSelected">
                                    <option v-for="(option, index) in product.measurementsOptions" :value="option"
                                        :key="index" :selected="option.selected">
                                        {{ option.text }}
                                    </option>
                                </select>
                            </div>
                            <div class="quantidade">
                                <div class="box-number">
                                    <div class="add-qtd" @click="minus"><i class="fa fa-minus"><span>-</span></i></div>
                                    <input type="text" name="quantidade" class="qtd" v-model="quantity" />
                                    <div class="add-qtd" @click="plus"><i class="fa fa-plus"><span>+</span></i></div>
                                </div>
                            </div>
                        </div>
                        <div class="group-valor" v-if="!productIn.price.discount.active">
                            <div class="js-valor js-desconto valor valor-desconto new-price 2">{{ getProductPrice }}
                            </div>
                        </div>
                        <div class="group-valor" v-else>
                            <div class="js-valor-desc valor old-price">{{ getDiscountInPercentege }} OFF</div>
                            <div class="js-valor valor old-price">de <span>{{ getProductPrice }}</span></div>
                            <div class="js-desconto valor-desconto new-price 1">por {{ getValueProductWithDiscount }}
                            </div>
                        </div>
                    </div> <!-- /group-int -->
                </div> <!-- /box-right -->
            </div> <!-- /box-descricao -->

            <div class="adicionar btn-store txt-adicionar gtag-btn-addtocart clickable">
                <div class="box-adicionar">
                    <span @click="buy">COMPRAR</span>
                </div>
            </div>
        </div>
        <ToastComponent ref="toast" :message="productIn.name" :iconUrl="productIn.dataSrc" />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ToastComponent from './Shared/ToastComponent.vue';

export default {
    components: {
        ToastComponent
    },
    props: {
        product: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            quantity: 1,
            measurementsOptionsSelected: null,
            isFavorite: false,
            productIn: null,
            toastVisible: false
        };
    },
    beforeMount() {
        console.log('load-product');
        this.initProduct();
    },
    watch: {
        measurementsOptionsSelected(productIn) {
            try {
                this.productIn = {
                    ...this.productIn,
                    ...productIn,
                    link: `/details/${productIn.productSameId}`,
                    selected: true
                };
            } catch (error) {
                console.error(error)
            }
        },
        product() {
            this.initProduct()
        },
    },
    computed: {
        getProductPrice() {
            return this.productIn ? this.productIn.price.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : '';
        },
        getDiscountInPercentege() {
            return this.productIn ? this.floatToPercentage(this.productIn.price.discount.value) : '';
        },
        getValueProductWithDiscount() {
            return this.productIn ? this.applyDiscountProductPrice() : '';
        },
        ...mapGetters('user', ['getLikes'])
    },
    methods: {
        ...mapActions('cart', ['addProduct']),
        ...mapActions('user', ['removeLike', 'addLike']),
        minus() {
            if (this.quantity > 1) {
                this.quantity--;
            }
        },
        plus() {
            this.quantity++;
        },
        buy() {
            const product = { ...this.productIn, quantity: this.quantity };
            this.addProduct(product);
            this.quantity = 1;
        },
        handleClick() {
            if (this.$store.state.auth.isLoggedIn) {
                this.likeAction();
            } else {
                this.$routerproductIn.push('/login');
            }
        },
        async likeAction() {
            try {
                const likeConfig = {
                    like: {
                        productId: this.productIn.productId,
                        productSameId: this.productIn.productSameId,
                        categoryId: this.productIn.categoryId
                    },
                    router: this.$router
                };
                if (!this.isFavorite) {
                    await this.addLike(likeConfig);
                    this.isFavorite = true;
                } else {
                    await this.removeLike(likeConfig);
                    this.isFavorite = false;
                }
            } catch (error) {
                console.error(error);
            }
        },
        floatToPercentage(num) {
            const rounded = Math.round(num * 100);
            return rounded + "%";
        },
        applyDiscountProductPrice() {
            if (this.productIn.price.discount.active) {
                const precoAtual = this.productIn.price.value;
                const novoPreco = precoAtual * (1 - this.productIn.price.discount.value);
                return novoPreco.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
            }
            return this.productIn.price;
        },
        initProduct() {
            const productSelectedId = this.product.selectedProduct;
            const productSelected = this.product.measurementsOptions.find(product => product.productId === productSelectedId);
            this.productIn = { ...productSelected };
            this.measurementsOptionsSelected = productSelected;
            const exist = this.getLikes.find(like => like.productSameId === this.productIn.productSameId);
            if (exist) {
                this.isFavorite = true
            } else {
                this.isFavorite = false
            }
        },
        launchToastComponent() {
            this.$refs.toast.showToast();
        }
    }
};
</script>
<style>
.max-height {
    min-height: 100%!important;
    min-width: 280px !important;
}


</style>
