<template>
    <li>
        <router-link :to="item?.path">
            <span class="icon">
                <picture>
                    <source :srcset="item.image.webp" type="image/webp" />
                    <img class="img-responsive" :src="item.image.png" :alt="item.title" />
                </picture>
            </span>
            <span class="title boxHeight">
                {{ item.title }}
            </span>
        </router-link>
    </li>
</template>

<script>
export default {
    name: 'MenuItemCategoriesHorizontal',
    props: {
        item: {
            type: Object,
            required: true
        }
    }
}
</script>
