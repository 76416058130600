<template>
    <MainLayout>
        <!-- Banner internas -->
        <section class="banner-interna"
            style="background-image: url(https://jullietcongelados.com.br/lojas/juliettecongelados/conteudo/uploads/6-63d855478c3e7.png);">
            <div class="pg-titulo">
                <div class="container">
                    <div class="row">
                        <div class="col-xs-12">
                            <h2 class="animated fadeIn">Pratos</h2>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- Breadcrumbs -->
        <BreadcrumbsComponent :items="breadcrumbItems" :activeIndex="activeIndex" />

        <!-- Content -->
        <main class="pg-internas">
            <div class="container">
                <div class="row">

                    <!-- Sidebar Left -->
                    <SidebarLeftComponent />
                    <!-- Detalhe Produto -->
                    <div class="col-xs-12 col-md-9 animated fadeIn">
                        <div class="row">
                            <!-- Voltar -->
                            <div class="col-xs-12" v-if="getBack">
                                <router-link :to="getBack"
                                    class="btn-voltar icon-left"><i class="fa fa-arrow-left"></i>Voltar</router-link>
                            </div>

                            <div class="box-detalhe-produto">

                                <GaleryPhotosProduct :capaImage="capaImage" :capaTitle="capaTitle" :capaAlt="capaAlt"
                                    :thumbs="thumbs" @update:capaImage="capaImage = $event" />

                                <BoxProductIn v-if="productSame" :product="productSame" />

                                <!-- Descrição do Produto -->
                                <!-- <ProductDescription /> -->

                            </div><!-- /box-detalhe-produto -->

                            <!-- Opiniões produto TRUSTVOX -->


                        </div>
                    </div>

                    <!-- Produtos relacionados -->

                </div>
            </div><!-- /container -->
        </main>

        <!-- Modal Novidades-->


        <!-- Modal Snapchat e Whatsapp -->

    </MainLayout>
</template>

<script>

import MainLayout from '@/layouts/MainLayout.vue';
import SidebarLeftComponent from '@/components/SidebarLeftComponent.vue'
// import ProductDescription from '@/components/DetailsProductPage/ProductDescription.vue';
import BoxProductIn from '@/components/DetailsProductPage/BoxProductIn.vue';
import GaleryPhotosProduct from '@/components/DetailsProductPage/GaleryPhotosProduct.vue';
import BreadcrumbsComponent from '@/components/BreadcrumbsComponent.vue';
import { mapActions, mapGetters } from 'vuex';
export default {
    components: {
        SidebarLeftComponent,
        GaleryPhotosProduct,
        BoxProductIn,
        // ProductDescription,
        MainLayout,
        BreadcrumbsComponent
    },
    beforeMount() {
        this.selectProductSameByProductSameId().then(() => {
            const productSame = this.getProductSame
            this.productSame = productSame
            this.productIn = productSame.measurementsOptions[0]
            const category = productSame.category
            this.breadcrumbItems = [
                ...this.breadcrumbItems,
                { label: category.title, url: `/categories/${category.categoryId}` },
                { label: this.productIn.name, url: `` },

            ]
            this.back = { path: this.breadcrumbItems?.[this.activeIndex - 1]?.url }
            this.capaImage = this.productIn.dataSrc
            console.log({
                productSame,
                "productIn-sorri": this.productIn,
            })
        })
    },
    computed: {
        ...mapGetters('shop', ['getProductSame']),
        getBack() {
            console.log({back: this.back})
            return this.back
        }
    },
    data() {
        return {
            productIn: null,
            productSame: null,
            imagens: [
                'https://octoshop.sfo2.digitaloceanspaces.com/lojas/juliettecongelados/uploads_produto/caldeirada-de-peixe-arroz-450g-6143629dd2935.jpg',
                'https://octoshop.sfo2.digitaloceanspaces.com/lojas/juliettecongelados/uploads_produto/caldeirada-de-peixe-arroz-450g-6143629dd2935.jpg',
                'https://octoshop.sfo2.digitaloceanspaces.com/lojas/juliettecongelados/uploads_produto/uma-oportunidade-muito-boa-em-diversos-itens-61-6143639c74a87.png',
            ],
            capaImage: 'https://octoshop.sfo2.digitaloceanspaces.com/lojas/juliettecongelados/uploads_produto/caldeirada-de-peixe-arroz-450g-6143629dd2935.jpg',
            thumbAtiva: 0,
            capaTitle: "test",
            capaAlt: "test",
            thumbs: [
                {
                    image: 'https://octoshop.sfo2.digitaloceanspaces.com/lojas/juliettecongelados/uploads_produto/caldeirada-de-peixe-arroz-450g-6143629dd2935.jpg',
                    title: 'Título da Miniatura 1',
                    alt: 'Descrição da Miniatura 1',
                },
                {
                    image: 'https://octoshop.sfo2.digitaloceanspaces.com/lojas/juliettecongelados/uploads_produto/caldeirada-de-peixe-arroz-450g-6143629dd2935.jpg',
                    title: 'Título da Miniatura 2',
                    alt: 'Descrição da Miniatura 2',
                },
                {
                    image: 'https://octoshop.sfo2.digitaloceanspaces.com/lojas/juliettecongelados/uploads_produto/uma-oportunidade-muito-boa-em-diversos-itens-61-6143639c74a87.png',
                    title: 'Título da Miniatura 2',
                    alt: 'Descrição da Miniatura 2',
                },
                // Adicione mais miniaturas conforme necessário
            ],
            breadcrumbItems: [
                { label: 'Home', url: '/' },
            ],
            activeIndex: 2,
            back:null

        }

    },
    methods: {
        ...mapActions('shop', ['setProductSame']),
        async selectProductSameByProductSameId() {
            try {
                await this.setProductSame(this.$route.params.productSameId)
            } catch (error) {
                console.log(error)
            }
        },
        alterarCapa(index) {
            this.capa = this.imagens[index];
        }

    }
}
</script>
