import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';

import Home from '@/views/HomePage.vue';
import About from '@/views/AboutPage.vue';
import Login from '@/views/LoginPage.vue';
import Register from '@/views/RegisterPage.vue';
import ShoppingCart from '@/views/ShoppingCartPage.vue';
import LoggedRegisted from '@/views/LoggedRegistedPage.vue';
import LoggedOrders from '@/views/LoggedOrdersPage.vue';
import LoggedListWishPage from '@/views/LoggedListWishPage.vue';
import LoggedCredit from '@/views/LoggedCreditPage.vue';
import LoggedReferAFriend from '@/views/LoggedReferAFriendPage.vue';
import LoggedPasswordChange from '@/views/LoggedPasswordChangePage.vue';
import LoggedLogoutPage from '@/views/LoggedLogoutPage.vue';

import CategoriesPage from '@/views/CategoriesPage.vue';
import DetailsProductPage from '@/views/DetailsProductPage.vue';
import CheckoutAdressPage from '@/views/CheckoutAdressPage.vue';

import PaymentPage from '@/views/RangerSlideDuo';
import LoggedDetailsOrderPage from '@/views/LoggedDetailsOrderPage.vue';

// Middleware para verificar se o usuário está logado
const isAuthenticated = async (to, from, next) => {
    // Verifica se o usuário está logado
    const isLoggedIn = store.state.auth_google.isLoggedIn;

    // Se o usuário estiver logado, permite o acesso à rota
    if (isLoggedIn) {

        next();
    } else {
        // Se o usuário não estiver logado, armazena a rota atual
        store.commit('auth_google/SET_REDIRECT_ROUTE', to.fullPath);
        // Redireciona para a página de login
        next({ name: 'Login' });
    }
};

const goToShopingCartPageIfCartItemsToBeZero = async (to, from, next) => {
    const isZeroCartItems = store.getters['cart/getQuantityFromCart'] > 0 ? true : false;
    if (isZeroCartItems) {
        next();
    } else {
        console.log({
            isZeroCartItems
        })
        next({ name: 'ShoppingCart' });
    }
}

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/about',
        name: 'About',
        component: About
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/register',
        name: 'Register',
        component: Register
    },
    {
        path: '/shopping-cart',
        name: 'ShoppingCart',
        component: ShoppingCart
    },
    {
        path: '/registed',
        name: 'LoggedRegisted',
        component: LoggedRegisted
    },
    {
        path: '/orders',
        name: 'LoggedOrders',
        component: LoggedOrders,
        beforeEnter: isAuthenticated
    },
    {
        path: '/categories/:categoryId',
        name: 'CategoriesPage',
        component: CategoriesPage
    },
    {
        path: '/orders/:orderId',
        name: 'LoggedDetailsOrderPage',
        component: LoggedDetailsOrderPage,
        beforeEnter: isAuthenticated
    },
    {
        path: '/favorites',
        name: 'LoggedListWishPage',
        component: LoggedListWishPage,
        beforeEnter: isAuthenticated
    },
    {
        path: '/credit',
        name: 'LoggedCredit',
        component: LoggedCredit,
        beforeEnter: isAuthenticated
    },
    {
        path: '/refer-friend',
        name: 'LoggedReferAFriend',
        component: LoggedReferAFriend,
        beforeEnter: isAuthenticated
    },
    {
        path: '/password-change',
        name: 'LoggedPasswordChange',
        component: LoggedPasswordChange,
        beforeEnter: isAuthenticated
    },
    {
        path: '/logout',
        name: 'LoggedLogoutPage',
        component: LoggedLogoutPage,
        beforeEnter: isAuthenticated
    },
    {
        path: '/details/:productSameId',
        name: 'DetailsProductPage',
        component: DetailsProductPage
    },
    {
        path: '/payment',
        name: 'PaymentPage',
        component: PaymentPage
    },
    {
        path: '/checkout',
        name: 'CheckoutAdressPage',
        component: CheckoutAdressPage,
        beforeEnter: [isAuthenticated, goToShopingCartPageIfCartItemsToBeZero]
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;
