<template>
  <CheckoutHeader />
  <CheckoutMain />
  <CheckoutFooter />
</template>
<script>
import CheckoutHeader from '@/components/Checkout/CheckoutHeader.vue'
import CheckoutMain from '@/components/Checkout/CheckoutMain.vue'
import CheckoutFooter from '@/components/Checkout/CheckoutFooter.vue'
import { mapActions } from 'vuex';
export default {
  components: {
    CheckoutHeader,
    CheckoutMain,
    CheckoutFooter
  },
  methods:{
    ...mapActions('checkout', ['resetCheckout'])
  },
  beforeUnmount() {
    this.resetCheckout()
  }
}
</script>
<style>
* {
  font-size: 1rem !important;
}
</style>
