<template>
    <div class="col-xs-12">
        <div class="box-filtro-produtos">
            <div class="box-filtro-itens">
                <div class="group-left">
                    <h3>Filtre seus produtos</h3>
                </div>
                <div class="group-right">
                    <h5>{{ filteredProducts.length }} itens</h5>
                    <span class="btn btn-filtrar-mob js-filtro-btn-mob" @click="toggleFilterVisibility">Selecionar
                        filtros</span>
                    <div class="box-btn-group" :style="{ display: isLargeScreen || isOpenFilter ? 'block' : 'none' }">
                        <!-- Preço -->
                        <div class="btn-group js-filtro-dropdown" :class="{ 'open': dropdown[0] }">
                            <span class="btn btn-line dropdown-toggle" data-toggle="dropdown"
                                @click="openDropdown(0)">Preço</span>
                            <div class="dropdown-menu">
                                <div class="dropdown-content">
                                    <div class="check-custom">
                                        <label v-for="(option, index) in pricingOptions" :key="index"
                                            :for="'preco_' + index">
                                            <input type="radio" name="preco" :id="'preco_' + index"
                                                :value="option.value" v-model="selectedPriceRange">
                                            <i class="fa fa-circle-o"></i><i class="fa fa-check-circle-o"></i>
                                            {{ option.label }}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Unidades -->
                        <div class="btn-group js-filtro-dropdown" :class="{ 'open': dropdown[1] }">
                            <span class="btn btn-line dropdown-toggle" data-toggle="dropdown"
                                @click="openDropdown(1)">Unidade</span>
                            <div class="dropdown-menu">
                                <div class="dropdown-content">
                                    <div class="group-selos categorias">
                                        <span v-for="(unit, index) in units" :key="index" class="item-selo"
                                            data-toggle="tooltip" :title="unit.unit">
                                            <div class="check-selo">
                                                <input type="checkbox" :value="unit.unit" v-model="selectedUnits"
                                                    :id="'unit_' + index">

                                                <label :for="'unit_' + index" style="padding: 10px 5px;">
                                                    <input type="checkbox" :value="unit.unit" v-model="selectedUnits">
                                                    <span>({{ unit.unit }})</span>
                                                </label>
                                            </div>
                                            <span class="badge">{{ unit.count }}</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Selos -->
                        <div class="btn-group js-filtro-dropdown js-filtro-selos" :class="{ 'open': dropdown[2] }">
                            <span class="btn btn-line dropdown-toggle" data-toggle="dropdown"
                                @click="openDropdown(2)">Selos</span>
                            <div class="dropdown-menu lg">
                                <div class="dropdown-content">
                                    <div class="group-selos categorias">
                                        <span v-for="seal in seals" :key="seal.id" class="item-selo"
                                            data-toggle="tooltip" :title="seal.name">
                                            <div class="check-selo">
                                                <input type="checkbox" :id="'selos_' + seal.id" :value="seal"
                                                    v-model="selectedSeals">
                                                <label :for="'selos_' + seal.id">
                                                    <img :src="seal.image" :alt="seal.name" width="50" height="50">
                                                </label>
                                            </div>
                                            <span class="badge">{{ seal.count }}</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Ordenação -->
                        <div class="btn-group js-filtro-dropdown" :class="{ 'open': dropdown[3] }">
                            <span class="btn btn-line dropdown-toggle" data-toggle="dropdown"
                                @click="openDropdown(3)">Ordenação</span>
                            <div class="dropdown-menu">
                                <div class="dropdown-content">
                                    <div class="check-custom">
                                        <label v-for="(option, index) in sortingOptions" :key="index"
                                            :for="'ordenacao_' + option.value">
                                            <input type="radio" name="ordenacao" :id="'ordenacao_' + option.value"
                                                :value="option" v-model="selectedSorting">
                                            <i class="fa fa-circle-o"></i><i class="fa fa-check-circle-o"></i>
                                            {{ option.label }}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button @click="applyFilters" class="btn btn-filtrar js-filtro-aplicar">Aplicar</button>
                    </div>
                </div>
            </div>
            <div class="box-filtro-resultado js-filtro-resultado" v-if="isFilterActive">
                <h6>Filtros ativos</h6>
                <div class="group scroll-mob">
                    <a v-if="selectedPriceRange" href="javascript:;" class="item-resultado js-filtro-range"
                        @click="clearPriceFilter()">
                        {{ selectedPriceRange.max.toFixed(2) }}
                        ×
                    </a>
                    <a v-for="(unit, index) in selectedUnits" :key="'unit-' + index" href="javascript:;"
                        class="item-resultado js-filtro-range" @click="clearUnitFilter(index)">
                        {{ unit }}
                        ×
                    </a>
                    <a v-for="(seal, index) in selectedSeals" :key="'seal-' + index" href="javascript:;"
                        class="item-resultado js-filtro-range" @click="clearSealFilter(index)">
                        {{ seal.name }}
                        ×
                    </a>
                    <a v-if="selectedSorting" href="javascript:;" class="item-resultado js-filtro-range"
                        @click="clearSortFilter()">
                        {{ selectedSorting.label }}
                        ×
                    </a>
                </div>

                <span class="btn btn-2 js-filtro-limpar" @click="filtersClean">Limpar filtros</span>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    data() {
        return {
            pricingOptions: [],
            units: [
                { label: 'Gramas', value: 'g' },
                // Adicione mais unidades conforme necessário
            ],
            sortingOptions: [
                { label: 'Menor preço', value: 'low_to_high' },
                { label: 'Maior preço', value: 'high_to_low' },
                { label: 'A - Z', value: 'a_to_z' },
                { label: 'Z - A', value: 'z_to_a' }
            ],
            seals: [],
            selectedPriceRange: null,
            selectedUnits: [],
            selectedSeals: [],
            selectedSorting: null,
            filteredProducts: [],
            categorySelected: null,
            isLargeScreen: false,
            isOpenFilter: false,
            dropdown: [
                false,
                false,
                false,
                false,
            ]
        };
    },
    computed: {
        ...mapGetters('shop', ['getCategorySelected']),
        getFilteredProducts() {
            return this.filteredProducts
        },
        isFilterActive() {
            if (this.selectedSeals.length || this.selectedUnits.length || this.selectedPriceRange || this.selectedSorting) return true
            return false
        },

    },
    created() {
        this.initializeFilters();
    },
    methods: {
        ...mapActions('shop', ['setProductsFilteredCategorySelected']),
        setCategorySelected() {
            if (this.getCategorySelected) {
                this.categorySelected = this.getCategorySelected
            }
        },
        initializeFilters() {
            this.setCategorySelected();
            this.getAllSeals();
            this.getAllUnits();
            this.getAllPrices();
            // Adiando a chamada para filterProducts após a configuração inicial
            this.$nextTick(() => {
                this.filterProducts();
            });
        },
        getAllSeals() {
            const allSeals = {};
            const products = this.categorySelected.products;
            products.forEach(product => {
                product.measurementsOptions.forEach(option => {
                    if (option.seals && Array.isArray(option.seals)) {
                        option.seals.forEach(seal => {
                            if (!allSeals[seal.sealId]) {
                                allSeals[seal.sealId] = {
                                    id: seal.sealId,
                                    image: seal.image,
                                    name: seal.title,
                                    count: 1
                                };
                            } else {
                                allSeals[seal.sealId].count++;
                            }
                        });
                    }
                });
            });
            this.seals = Object.values(allSeals);
        },
        getAllPrices() {
            const prices = [];
            const products = this.categorySelected.products;
            products.forEach(product => {
                product.measurementsOptions.forEach(option => {
                    const price = option.price.discount.active ? option.price.value * (1 - option.price.discount.value) : option.price.value;
                    let exists = false;
                    prices.forEach(existingPrice => {
                        if (existingPrice.max >= price) {
                            exists = true;
                        }
                    });
                    if (!exists) {
                        prices.push({ min: 0, max: price });
                    }
                });
            });
            prices.sort((a, b) => a.max - b.max);
            this.pricingOptions = prices.map(price => ({
                label: `Até R$ ${price.max.toFixed(2)}`,
                value: price
            }));
        },
        getAllUnits() {
            const allUnits = {};
            const products = this.categorySelected.products;
            products.forEach(product => {
                product.measurementsOptions.forEach(option => {
                    const unitKey = option.text; // Usando o texto como chave para representar as unidades
                    if (!allUnits[unitKey]) {
                        allUnits[unitKey] = {
                            unit: unitKey,
                            count: 1
                        };
                    } else {
                        allUnits[unitKey].count++;
                    }
                });
            });
            this.units = Object.values(allUnits);
        },
        filterProducts() {
            let filteredProducts = JSON.parse(JSON.stringify(this.categorySelected.products)); // Criar uma cópia dos produtos
            if (this.selectedPriceRange) {
                filteredProducts = this.filterProductsByPrice(filteredProducts);
            }
            if (this.selectedSeals.length > 0) {
                filteredProducts = this.filterProductsBySeal(filteredProducts);
            }
            if (this.selectedUnits.length > 0) {
                filteredProducts = this.filterProductsByUnit(filteredProducts);
            }
            if (this.selectedSorting) {
                filteredProducts = this.sortProducts(filteredProducts, this.selectedSorting.value)
            }

            // Verifique se selectedProduct é uma opção válida dentro de measurementsOptions
            filteredProducts.forEach(product => {
                const matchingOption = product.measurementsOptions.find(option => option.productId === product.selectedProduct);
                if (!matchingOption) {
                    // Se não houver correspondência, atualize selectedProduct para a primeira opção
                    product.selectedProduct = product.measurementsOptions[0].productId;
                }
            });

            this.filteredProducts = filteredProducts; // Atualize a lista de produtos filtrados
            this.setProductsFilteredIntoCategorySelected(filteredProducts);

        },
        filterProductsByUnit(products) {
            const filteredProducts = [];
            products.forEach(product => {
                const filteredOptions = product.measurementsOptions.filter(option => {
                    return this.selectedUnits.includes(option.text);
                });
                if (filteredOptions.length > 0) {
                    const clonedProduct = { ...product, measurementsOptions: filteredOptions };
                    filteredProducts.push(clonedProduct);
                }
            });
            return filteredProducts;
        },
        filterProductsByPrice(products) {
            const filteredProducts = [];
            products.forEach(product => {
                const filteredOptions = product.measurementsOptions.filter(option => this.checkPrice(option));
                if (filteredOptions.length > 0) {
                    const clonedProduct = { ...product };
                    clonedProduct.measurementsOptions = filteredOptions;
                    filteredProducts.push(clonedProduct);
                }
            });
            return filteredProducts;
        },
        filterProductsBySeal(products) {
            const filteredProducts = [];
            products.forEach(product => {
                const filteredOptions = product.measurementsOptions.filter(option => {
                    return option.seals.some(seal => {
                        return this.selectedSeals.some(selectedSeal => selectedSeal.id === seal.sealId);
                    });
                });
                if (filteredOptions.length > 0) {
                    const clonedProduct = { ...product, measurementsOptions: filteredOptions };
                    filteredProducts.push(clonedProduct);
                }
            });
            return filteredProducts;
        },
        sortProductsFiltered(products) {
            this.selectedSorting.value

            return this.sortProducts(products,)
        },
        sortProducts(products, sorting) {
            const sortMap = {
                'low_to_high': (a, b) => this.getMinPriceOfAllOptions(a) - this.getMinPriceOfAllOptions(b),
                'high_to_low': (a, b) => this.getMaxPriceOfAllOptions(b) - this.getMaxPriceOfAllOptions(a),
                'a_to_z': (a, b) => (this.getProductNameOfAllOptions(a) || '').localeCompare(this.getProductNameOfAllOptions(b) || ''),
                'z_to_a': (a, b) => (this.getProductNameOfAllOptions(b) || '').localeCompare(this.getProductNameOfAllOptions(a) || '')
            };

            const sortingFunction = sortMap[sorting];
            if (sortingFunction) {
                products.sort(sortingFunction);
            }
            return products;
        },
        getMinPriceOfAllOptions(product) {
            let minPrice = Infinity;
            product.measurementsOptions.forEach(option => {
                const price = option.price.discount.active ? option.price.value * (1 - option.price.discount.value) : option.price.value;
                if (price < minPrice) {
                    minPrice = price;
                }
            });
            return minPrice;
        },
        getMaxPriceOfAllOptions(product) {
            let maxPrice = -Infinity;
            product.measurementsOptions.forEach(option => {
                const price = option.price.discount.active ? option.price.value * (1 - option.price.discount.value) : option.price.value;
                if (price > maxPrice) {
                    maxPrice = price;
                }
            });
            return maxPrice;
        },
        getProductNameOfAllOptions(product) {
            // Concatenar todos os nomes das opções de medição
            return product.measurementsOptions.map(option => option.name).join(', ');
        },
        getMinPrice(product) {
            let minPrice = Infinity;
            product.measurementsOptions.forEach(option => {
                const price = option.price.discount.active ? option.price.value * (1 - option.price.discount.value) : option.price.value;
                if (price < minPrice) {
                    minPrice = price;
                }
            });
            return minPrice;
        },
        checkPrice(option) {
            if (!this.selectedPriceRange) return true;
            const price = option.price.discount.active ? option.price.value * (1 - option.price.discount.value) : option.price.value;
            return price >= this.selectedPriceRange.min && price <= this.selectedPriceRange.max;
        },
        applyFilters() {
            this.filterProducts();
            this.toggleFilterVisibility()
        },
        filtersClean() {
            this.selectedPriceRange = null;
            this.selectedUnits = [];
            this.selectedSeals = [];
            this.selectedSorting = null;
            this.filterProducts()
        },
        clearPriceFilter() {
            this.selectedPriceRange = null;
        },
        clearSortFilter() {
            this.selectedSorting = null;
        },
        clearUnitFilter(index) {
            this.selectedUnits.splice(index, 1);
        },
        clearSealFilter(index) {
            this.selectedSeals.splice(index, 1);
        },
        setProductsFilteredIntoCategorySelected(filteredProducts) {
            this.setProductsFilteredCategorySelected(filteredProducts);
        },
        checkScreenSize() {
            this.isLargeScreen = window.innerWidth > 768; // Por exemplo, consideramos 768 como a largura mínima para uma tela grande
        },
        toggleFilterVisibility() {
            this.isOpenFilter = !this.isOpenFilter;
        },
        openDropdown(index) {
            this.dropdown = this.dropdown.map((d, i) => (d != true && i === index ? true : false))
        }
    },
    mounted() {
        // Verifique o tamanho da tela ao montar o componente
        this.checkScreenSize();
        // Adicione um listener para verificar se a tela é redimensionada
        window.addEventListener('resize', this.checkScreenSize);
    },
    beforeUnmount() {
        // Remova o listener quando o componente for destruído para evitar vazamentos de memória
        window.removeEventListener('resize', this.checkScreenSize);
    }

};
</script>
