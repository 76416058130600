import { database, dbRef, dbSet, dbGet, dbRemove, dbPush } from '@/firebase/index';

export const createOrder = (uid, shopId, orderData) => {
    const orderRef = dbRef(database, `shops/${shopId}/users/${uid}/orders`);
    const newOrderRef = dbPush(orderRef);
    return dbSet(newOrderRef, orderData);
}

export const getOrders = async (uid, shopId) => {
    try {
        const ordersSnapshot = await dbGet(dbRef(database, `shops/${shopId}/users/${uid}/orders`));
        const ordersObject = ordersSnapshot.val()
        if (ordersObject !== null) {
            const ordersArray = Object.values(ordersObject);
            return ordersArray;
        } else {
            return [];
        }
    } catch (error) {
        console.error("Erro ao buscar os pedidos:", error);
        throw error;
    }
}



export const getOrder = (uid, shopId, orderId) => {
    const orderRef = dbRef(database, `shops/${shopId}/users/${uid}/orders/${orderId}`);
    return dbGet(orderRef);
}

export const updateOrder = (uid, shopId, orderId, updatedOrderData) => {
    const orderRef = dbRef(database, `shops/${shopId}/users/${uid}/orders/${orderId}`);
    return dbSet(orderRef, updatedOrderData);
};

export const deleteOrder = (uid, shopId, orderId) => {
    const orderRef = dbRef(database, `shops/${shopId}/users/${uid}/orders/${orderId}`);
    return dbRemove(orderRef);
}
export const createOrderDetail = (uid, shopId, orderId, orderDetailData) => {
    const orderDetailRef = dbRef(database, `shops/${shopId}/users/${uid}/orderDetails/${orderId}/`);
    return dbSet(orderDetailRef, orderDetailData);
}


export const getOrderDetails = (uid, shopId) => {
    const orderDetailsRef = dbRef(database, `shops/${shopId}/users/${uid}/orderDetails`);
    return dbGet(orderDetailsRef);
}

export const getOrderDetailByOrderId = async (uid, shopId, orderId) => {
    try {
        const orderDetailRef = dbRef(database, `shops/${shopId}/users/${uid}/orderDetails/${orderId}`);
        const snapshot = await dbGet(orderDetailRef);

        if (snapshot.exists()) {
            return snapshot.val();
        }

        throw new Error('Detalhes do pedido não encontrados.');
    } catch (error) {
        console.error('Erro ao buscar detalhes do pedido:', error);
        throw error;
    }
}



export const updateOrderDetail = (uid, shopId, orderDetailId, updatedOrderDetailData) => {
    const orderDetailRef = dbRef(database, `shops/${shopId}/users/${uid}/orderDetails/${orderDetailId}`);
    return dbSet(orderDetailRef, updatedOrderDetailData);
};

export const deleteOrderDetail = (uid, shopId, orderDetailId) => {
    const orderDetailRef = dbRef(database, `shops/${shopId}/users/${uid}/orderDetails/${orderDetailId}`);
    return dbRemove(orderDetailRef);
}

export const createAddress2 = (uid, shopId, addressData) => {
    const addressRef = dbRef(database, `shops/${shopId}/users/${uid}/addresses`);
    const newAddressRef = dbPush(addressRef);
    return dbSet(newAddressRef, addressData);
}

export const createAddress22 = (uid, shopId, addressData) => {
    const addressRef = dbRef(database, `shops/${shopId}/users/${uid}/addresses`);
    return new Promise((resolve, reject) => {
        const newAddressRef = dbPush(addressRef);
        const newAddressUid = newAddressRef.key;
        if (newAddressUid) {
            resolve({
                [newAddressUid]: addressData
            });
        } else {
            reject("Erro ao gerar UID para o novo endereço.");
        }
    });
}


export const createAddress = (uid, shopId, addressData) => {
    const addressRef = dbRef(database, `shops/${shopId}/users/${uid}/addresses`);
    return new Promise((resolve, reject) => {
        const newAddressRef = dbPush(addressRef);
        const newAddressUid = newAddressRef.key;
        if (newAddressUid) {
            // Use set() para salvar os dados do endereço no novo nó
            dbSet(newAddressRef, { ...addressData, addressId: newAddressUid })
                .then(() => {
                    // Resolva com um objeto contendo a chave e o objeto do endereço criado
                    resolve({
                        [newAddressUid]: { ...addressData, addressId: newAddressUid }
                    });
                })
                .catch(error => {
                    reject("Erro ao salvar dados do endereço: " + error.message);
                });
        } else {
            reject("Erro ao gerar UID para o novo endereço.");
        }
    });
}



export const getAddresses = async (uid, shopId) => {
    try {
        const addressesSnapshot = await dbGet(dbRef(database, `shops/${shopId}/users/${uid}/addresses`));
        const addressesObject = addressesSnapshot.val()
        if (addressesObject !== null) {
            const addressesArray = (addressesObject);
            return addressesArray;
        } else {
            return [];
        }
    } catch (error) {
        console.error("Erro ao buscar os pedidos:", error);
        throw error;
    }
}

export const getAddressByAddressId = async (uid, shopId, addressId) => {
    try {
        const addressRef = dbRef(database, `shops/${shopId}/${uid}/addresses/${addressId}`);
        const snapshot = await dbGet(addressRef);

        if (snapshot.exists()) {
            return snapshot.val();
        }

        throw new Error('Endereço não encontrado.');
    } catch (error) {
        console.error('Erro ao buscar endereço:', error);
        throw error;
    }
}
